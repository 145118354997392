.education{
  width:100%;
}

.postWrapper {
  display:flex;
  justify-content: flex-start;
  flex-direction:row;
  margin-bottom:var(--spacer);
}

.timelineWrapper{
  display:flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width:75px;
  position:relative;
}

.timelineDot{
  font-size:.75em;
  margin-top:.35em;
  z-index:2;
}

.timeline{
  border:1px solid var(--shadow-clr);
  position:absolute;
  left:50%;
  transform:translateX(-50%);
  width:1px;
  height:135%;
  z-index:1;
  top:-.5em;
}

.educationDetails{
  width:calc(100% - 75px);
}

/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {
  .educationWrapper .timelineDot{
    margin-left:.55em;
  }
  .timelineWrapper{
    width:50px;
    align-items:flex-start;
  }
  .timeline{
    left:25%;
  }
  .educationDetails, .articleDetailWrapper{
    width:calc(100% - 50px) !important;
  }
}

/* #endregion */
