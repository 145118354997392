#root {
  padding-left: calc(100vw - 100%);
  padding-bottom:4em;
  padding-bottom:var(--spacer-thicc);
  margin-top: 8em;
  margin-top: var(--spacer-thiccer);
}

@media only screen and (max-width: 900px) {
  #root {
    padding-left: 0;
    margin-top: 1em !important;
    margin-top: var(--spacer) !important;
  }
}

:root{
  --spacer-thiccer: 8em;
  --spacer-thicc: 4em;
  --spacer-thick: 2em;
  --spacer: 1em;
  --spacer-half: 0.5em;
  --spacer-quarter: 0.25em;
  --border-radius:5px;
  --max-width-mobile: 900px;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --shadow-clr: rgba(100, 100, 111, 0.2);
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #323232;
}

/* NEITHER PROFESSION VARIABLES */
.wonmin {
  --clr-primary: #e86666;
}

/* GAMES PROFESSION VARIABLES */
.games {
  --clr-primary: #5f5aa2;
}

/* WEB PROFESSION VARIABLES */
.web {
  --clr-primary: #7daf9c;
}

body {
  margin: 0;
  font-family: "Poppins", "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:white;
  margin:0;
  border:0;
  padding:0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noselect {
  -webkit-user-drag: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.is-primary{color:#e86666;}
.is-secondary{color:#cc;}
.is-tertiary{color:#5f5aa2;}
.is-quaternary{color:#7daf9c;}

.is-bg-primary{background-color:#e86666 !important;color:#fff;color:var(--clr-bg-alt);}
.is-bg-secondary{background-color:#f1db4b !important;color:#323232;}
.is-bg-tertiary{background-color:#5f5aa2 !important;color:#fff;color:var(--clr-bg-alt);}
.is-bg-quaternary{background-color:#7daf9c !important;color:#fff;color:var(--clr-bg-alt);}

.is-inverted.is-bg-primary{background-color:#fff !important;background-color:var(--clr-bg-alt) !important;border-color:#e86666;color:#e86666;}
.is-inverted.is-bg-secondary{background-color:#fff !important;background-color:var(--clr-bg-alt) !important;border-color:#f1db4b;color:#f1db4b;}
.is-inverted.is-bg-tertiary{background-color:#fff !important;background-color:var(--clr-bg-alt) !important;border-color:#5f5aa2;color:#5f5aa2;}
.is-inverted.is-bg-quaternary{background-color:#fff !important;background-color:var(--clr-bg-alt) !important;border-color:#7daf9c;color:#7daf9c;}

.is-rounded-corner {border-radius: 5px;border-radius: var(--border-radius);}

.is-hidden{
  display:none !important;
}

@media only screen and (min-width: 901px) {
  .is-hidden-desktop{
    display:none !important;
  }
}

@media only screen and (max-width: 900px) {
  .is-hidden-mobile{
    display:none !important;
  }
}

/* <editor-fold-------------------------------GLOBAL STYLES------------------------------- */

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: #555;
  color: var(--clr-fg);
  background-color: #fcfcfc;
  background-color: var(--clr-bg);
}

::-webkit-selection,
::selection {
  background: var(--clr-primary);
  color: #fcfcfc;
  color: var(--clr-bg);
}

h1,
h2,
h3,
h4 {
  line-height: 1.2;
  color: #323232;
  color: var(--clr-fg-alt);
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.15rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

@media (max-width: 900px) {
  h1 {
    font-size: 2.6rem;
  }
}

/* #endregion------------------------------------------------------------------------------------- */

/* <editor-fold-------------------------------BUTTONS + LINKS------------------------------- */

.button {
  box-shadow:0 0.25em 0 rgba(100, 100, 111, 0.2);
  box-shadow:0 0.25em 0 var(--shadow-clr);
  border:.1em solid rgba(100, 100, 111, 0.2);
  border:.1em solid var(--shadow-clr);
  border-radius:5px;
  border-radius:var(--border-radius);
  text-align:center;
  padding:0.5em;
  padding:var(--spacer-half);
  transition: -webkit-transform 0.05s ease-in-out;
  transition: transform 0.05s ease-in-out;
  transition: transform 0.05s ease-in-out, -webkit-transform 0.05s ease-in-out;
}
.button svg{
  padding-right:.5em;
}
.button:active {
  -webkit-transform:translateY(5px);
          transform:translateY(5px);
  box-shadow:none;
}
.button.is-bg-primary:hover{border:.1em solid #323232}
.button.is-bg-secondary:hover{border:.1em solid #323232}
.button.is-bg-tertiary:hover{border:.1em solid #323232}
.button.is-bg-quaternary:hover{border:.1em solid #323232}

.link {
  -webkit-user-drag: none;
  color: var(--clr-primary);
  position: relative;
}

.link.textDecoration {
  text-decoration: underline;
}

.link:not(.noline){
  padding: 0 0 0.3em 0;
}

.link:hover {
  color: var(--clr-primary);
}

.link:not(.noline)::before {
  content: "";
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--clr-primary);
  transition: width 0.2s ease-in;
}

.link--icon:not(.noline)::before{
  bottom:.2em !important;
}

.link:not(.noline):hover::before,
.link:not(.noline):focus::before {
  width: 100%;
}

.link--nav {
  color: #555;
  color: var(--clr-fg);
  text-transform: lowercase;
  font-weight: 500;
}

.link--icon {
  color: #555;
  color: var(--clr-fg);
  font-size: 1.2rem;
  cursor:pointer;
}

.btn {
  display: block;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: lowercase;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.btn--outline {
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn--outline:focus,
.btn--outline:hover {
  color: #fcfcfc;
  color: var(--clr-bg);
}

.btn--outline:before {
  content: "";
  position: absolute;
  background-color: var(--clr-primary);
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.btn--outline:hover::before,
.btn--outline:focus::before {
  right: 0;
}

.btn--plain {
  text-transform: initial;
  background-color: #fff;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
}

.btn--plain:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

/* #endregion------------------------------------------------------------------------------------- */

/* #region ---------------------------------------LAYOUT---------------------------------------------- */

.center {
  display: flex;
  align-items: center;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
}

/* #endregion------------------------------------------------------------------------------------- */

/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {

}

/* #endregion */

#navbar.is-fixed{
  padding-left: calc(100vw - 100%);
  position:fixed;
  top:0;
  width:100%;
  z-index:999;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
}

#navbar.is-fixed .header{
  height: 3.5em;
}

#navbar.border-bottom{
  border-bottom:1px solid var(--shadow-clr);
}

.header {
  max-width:calc(1200px + var(--spacer-thick));
  padding-left:var(--spacer-thick);
  padding-right:var(--spacer-thick);
  margin: auto;
  justify-content: space-between;
}

.nav.left {
  display:flex;
  justify-content: flex-start;
  align-items: center;
}

.btn--icon.color-always{
  margin-left:var(--spacer-quarter);
}

.nav__list {
  display: flex;
}

.nav__list-item {
  margin-left: 1.5em;
  cursor:pointer;
}

.nav__hamburger {
  display: none;
  width: 1em;
}

.btn--icon {
  padding: 0;
  font-size: 1.2rem;
  color: var(--clr-primary);
}

.logoNav:active .btn--icon {
  -webkit-transform: translate(2px) rotate(15deg);
          transform: translate(2px) rotate(15deg);
}

/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {
  #navbar.is-fixed{
    padding-left: 0;
  }
}


/* #endregion */

.introSection .mainBody{
  padding-bottom:0;
}

.mainBody {
  display:flex;
  justify-content: center;
  align-items: center;
  margin:auto;
  max-width:calc(1200px + var(--spacer-thick));
  padding:var(--spacer-thick);
  overflow:hidden;
}

.mainBody.row{ flex-direction: row;}
.mainBody.col{ flex-direction: column;}
.mainBody.spaceBetween { justify-content: space-between; }
.mainBodyLeft{
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align:left;
}

.name{
  margin-top:0;
  margin-bottom:var(--spacer-half);
}

.nameSub {
  color:var(--clr-fg-alt);
  font-weight:bold;
  margin:auto;
  font-size: 1.5rem;
}

.wonminImage{
  -webkit-user-drag: none;
  width:400px;
  border-radius:100%;
  padding-left:var(--spacer-thick);
}

.socialLinksWrapper{
  margin-top:var(--spacer-thick);
}

.socialLinksWrapper .link {
  font-size:2rem;
  margin-top:-.25em;
}
.socialLinksWrapper .link:not(:last-child) {
  margin-right:var(--spacer-half);
}

.titlesWrapper{
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin-top:var(--spacer);
}

.titlesWrapper div:first-child {
  margin-left:0;
}

.titlesWrapper .button {
  margin:0;
  width:calc(50% - .5em);
  cursor:pointer;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
}


/* #region -----------------SCROLL-----------------------------------*/

.scrollSection{
  padding-bottom:var(--spacer-thicc);
}

.scrollContainer {
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  padding:2em;
  padding-bottom:calc(2em + 35px);
}

.scrollContainer > div{
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
}

.scrollHorizontalContainer {
  display:flex;
  flex-direction:row;
  justify-content: center;
  text-align:center;
  align-items: top;
}

.scrollHorizontalContainer:first-child{
  padding-bottom:1em;
}

.scrollHorizontalContainer p{
  margin-bottom:0;
  font-size:0.65em;
  line-height: 100%;
  cursor:pointer;
}

#animatedScroll {
  -webkit-animation: scrolling 1s ease-in-out infinite;
          animation: scrolling 1s ease-in-out infinite;
}

@-webkit-keyframes scrolling {
  from {
    height:0;
  }
  to {
    height:35px;
  }
}

@keyframes scrolling {
  from {
    height:0;
  }
  to {
    height:35px;
  }
}

.scrollVerticalContainer{
  height:35px;
}

.scrollHorizontalContainer div:first-child{
  border-right: 0.25px solid rgba(0,0,0,.45);
}

/* #endregion */

/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {
  .games .introSection, .web .introSection{
    min-height:100vh;
  }
  .introSection{
    margin-top:var(--spacer-thick);
  }
  .mainBody{
    flex-direction: column-reverse !important;
  }
  .mainBodyLeft{
    width:100%;
    margin-top:calc(var(--spacer) * -1);
    z-index:9;
  }
  .titlesWrapper .button{
    width:100% !important;
    margin-bottom:var(--spacer) !important
  }
  .wonminImage{
    padding-left:0;
    width:200px;
    min-height:232px;
    padding-bottom:var(--spacer-thick);
  }
  .name{
    text-align: center;
    font-size:1.9rem;
  }
  .nameSub{
    text-align: center;
    font-size:1.15rem;
  }
  .titleText{
    text-align: center;
    font-size:1.15rem;
  }
  .titlesWrapper{
    flex-direction: column;
  }
  .socialLinksWrapper{
    display:flex;
    justify-content: center;
    margin-top:var(--spacer-half);
    margin-bottom:var(--spacer-half);
  }
  .about__desc{
    text-align:justify;
  }
}

/* #endregion */

.projectSection{
  padding-bottom: var(--spacer-thicc);
}

.projectSection .section__title{
  margin-bottom:var(--spacer-half);
}

.projects__grid {
  margin: 0 auto;
  overflow:visible;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 2em;
}

.projects__grid:not(:last-child) {
  margin-bottom: var(--spacer-thicc);
}

.project {
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--clr-bg-alt);
}

@media only screen and (max-width: 1250px) {
  .project {
    cursor:pointer;
  }
}

.worksSwiper .projectDetails{
  padding-bottom:0;
}

.worksSwiper .projectDetails h3 .link{
  white-space:nowrap;
}

.projectsSwiper .projectDetails{
  padding-bottom:0;
}

.projectDetails {
  padding:var(--spacer-thick);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.projectName{
  display:flex;
  width:100%;
  justify-content: center;
}

.projectName .link{
  display:flex;
  align-items: center;
}

.project__description {
  margin-top: 1em;
}

.project__subtitle {
  display:flex;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--shadow);
  margin-top:var(--spacer);
}

.inlineSpan{
  padding-top:var(--spacer-half);
}

.inlineFA {
  font-size:0.75rem;
  padding-left:0.35rem;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.project__links{
  display:flex;
  justify-content: center;
}

.project .link--icon {
  margin-left: var(--spacer-quarter);
  margin-right: var(--spacer-quarter);
  font-size:2rem;
}

.projectImage {
  width:100%;
  object-fit:cover;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.tooltip{
  font-size:.8em;
  line-height:125%;
}

.sideproject__title{
  padding-top:var(--spacer);
}


/* #region -----------------PAGINATION-----------------------------------*/
.customNavigationWrapper{
  display:flex;
  margin:auto;
  width:150px;
  justify-content: space-between;
}
.customNavigationButton{
  font-size:1em;
  cursor:pointer;
  color: var(--clr-primary);
}

.swiperSection {
  max-width:calc(1200px + var(--spacer-thick));
}
.swiper-wrapper {
  margin-top:var(--spacer-thick);
}
.swiper-button-prev, .swiper-button-next, .swiper-pagination {
  background:transparent;
}
.projectsSwiper .swiper-button-prev, .projectsSwiper .swiper-button-next{
  top:28% !important;
}
.swiper-button-prev, .swiper-button-next{
  color: var(--clr-primary);
  padding:var(--spacer);
  border-radius:100%;
  top:12%;
}
.swiper-button-disabled{
  opacity:0 !important;
}
.swiper-button-prev::after, .swiper-button-next::after{
  font-size:1.5em;
  font-weight:bolder;
}
.swiper-pagination-bullet{
  background:var(--clr-primary);
}
.swiper-pagination{
  top:calc(var(--spacer) * -1.25);
  bottom:revert !important;
  left: 50% !important;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100px !important;
  margin: auto;
}
.swiperIcon {
  padding-right:.5em;
  display:flex;
  justify-content: space-between;
  align-items: flex-start
}

.swiperIconArrow{
  font-size:.5em;
}

/* #endregion */


/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {
  .project__description{
    text-align: justify;
  }
  .inlineSpan{
    text-align: center;
  }
}

/* #endregion */

.checkboxWrapper{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.checkboxContainer{
  cursor:pointer;
  font-size:1rem;
  margin-left:var(--spacer-half);
  margin-right:var(--spacer-half);
}

.checkboxContainer span{
  margin-left:var(--spacer-quarter);
}

.skills__list {
  max-width: 500px;
  width: 95%;
  margin: 0 auto;
  margin-top:var(--spacer);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
}

/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {
  .skills.section, .section.education {
    margin-top:0;
    padding-top:var(--spacer-thick);
    padding-bottom:var(--spacer-thick);
  }
  .skills.section {
    margin-top:0;
    padding-top:var(--spacer-thick);
    padding-bottom:var(--spacer-thick);
  }
  .section.education {
    margin-top:0;
    padding-top:var(--spacer-thick);
    padding-bottom:0;
  }
}

/* #endregion */

.education{
  width:100%;
}

.postWrapper {
  display:flex;
  justify-content: flex-start;
  flex-direction:row;
  margin-bottom:var(--spacer);
}

.timelineWrapper{
  display:flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width:75px;
  position:relative;
}

.timelineDot{
  font-size:.75em;
  margin-top:.35em;
  z-index:2;
}

.timeline{
  border:1px solid var(--shadow-clr);
  position:absolute;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
  width:1px;
  height:135%;
  z-index:1;
  top:-.5em;
}

.educationDetails{
  width:calc(100% - 75px);
}

/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {
  .educationWrapper .timelineDot{
    margin-left:.55em;
  }
  .timelineWrapper{
    width:50px;
    align-items:flex-start;
  }
  .timeline{
    left:25%;
  }
  .educationDetails, .articleDetailWrapper{
    width:calc(100% - 50px) !important;
  }
}

/* #endregion */

.pressRows{
  max-width:900px;
  margin:auto;
}

.press__date{
  font-weight:normal;
  font-size:.75em;
}

.pressWrapper:hover .timelineDot{
  -webkit-transform:translateX(5px);
          transform:translateX(5px);
}

.pressWrapper .timelineDot{
  font-size:1.5em;
  margin-top:.35em;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.pressWrapper .articleDetailWrapper{
  width:calc(100% - 75px);
}

@media only screen and (max-width: 900px) {
  .pressRows{
    max-width:100%;
  }
}

.experiences__grid{
  max-width: 750px;
  margin: 0 auto;
  display: grid;
  grid-gap: 2em;
}

.experiences__grid .project {
  width:100%;
}

.inlineFAtitle{
  font-size:.75em;
  padding-left:.25em;
  padding-bottom:.05em;
}

.experience .projectTitle {
  margin-bottom:var(--spacer-quarter);
}

.experience .project__subtitle{
  margin-top:0;
}


.interests{
  max-width:100%;
}

.interests .skills__list{
  max-width:100%;
}

.interests .skills__list-item.is-inverted{
  border-style:solid !important;
  border-width:1px !important;
}

.interests .skills__list-item {
  font-size:.8rem;
  padding:var(--spacer-half);
}

.footer {
  position:fixed;
  bottom:var(--spacer);
  right:var(--spacer);
  font-size:2rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  color:#FFF;
  cursor:pointer;
  height:46px;
  background-color:transparent;
}

.footerIcon{
  box-shadow:var(--shadow);
  width:30px;
  height:30px;
  border-radius:100%;
  background-color:var(--clr-primary);
  padding:var(--spacer-quarter);
  border:.05em solid transparent;
}

.footer:hover .footerIcon{
  border:.05em solid #323232
}

.footer:active {
  -webkit-transform:translateY(-5px);
          transform:translateY(-5px);
}

.skillsAndEducation{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  max-width:calc(1200px + var(--spacer-thick));
  padding:var(--spacer-thick);
  margin:auto;
}

.skillsAndEducation .mainBody{
  width:45%;
  align-items: flex-start;
  min-height:300px;
  margin:0;
  padding:0;
}

/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {
  .skillsAndEducation{
    flex-direction: column;
    max-width:100%;
  }
  .skillsAndEducation .mainBody{
    width:100%;
    min-height:410px;
    justify-content: flex-end;
    align-items: center;
    padding:0;
  }
}

/* #endregion */

