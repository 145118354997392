.introSection .mainBody{
  padding-bottom:0;
}

.mainBody {
  display:flex;
  justify-content: center;
  align-items: center;
  margin:auto;
  max-width:calc(1200px + var(--spacer-thick));
  padding:var(--spacer-thick);
  overflow:hidden;
}

.mainBody.row{ flex-direction: row;}
.mainBody.col{ flex-direction: column;}
.mainBody.spaceBetween { justify-content: space-between; }
.mainBodyLeft{
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align:left;
}

.name{
  margin-top:0;
  margin-bottom:var(--spacer-half);
}

.nameSub {
  color:var(--clr-fg-alt);
  font-weight:bold;
  margin:auto;
  font-size: 1.5rem;
}

.wonminImage{
  -webkit-user-drag: none;
  width:400px;
  border-radius:100%;
  padding-left:var(--spacer-thick);
}

.socialLinksWrapper{
  margin-top:var(--spacer-thick);
}

.socialLinksWrapper .link {
  font-size:2rem;
  margin-top:-.25em;
}
.socialLinksWrapper .link:not(:last-child) {
  margin-right:var(--spacer-half);
}

.titlesWrapper{
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin-top:var(--spacer);
}

.titlesWrapper div:first-child {
  margin-left:0;
}

.titlesWrapper .button {
  margin:0;
  width:calc(50% - .5em);
  cursor:pointer;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
}


/* #region -----------------SCROLL-----------------------------------*/

.scrollSection{
  padding-bottom:var(--spacer-thicc);
}

.scrollContainer {
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  padding:2em;
  padding-bottom:calc(2em + 35px);
}

.scrollContainer > div{
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
}

.scrollHorizontalContainer {
  display:flex;
  flex-direction:row;
  justify-content: center;
  text-align:center;
  align-items: top;
}

.scrollHorizontalContainer:first-child{
  padding-bottom:1em;
}

.scrollHorizontalContainer p{
  margin-bottom:0;
  font-size:0.65em;
  line-height: 100%;
  cursor:pointer;
}

#animatedScroll {
  animation: scrolling 1s ease-in-out infinite;
}

@keyframes scrolling {
  from {
    height:0;
  }
  to {
    height:35px;
  }
}

.scrollVerticalContainer{
  height:35px;
}

.scrollHorizontalContainer div:first-child{
  border-right: 0.25px solid rgba(0,0,0,.45);
}

/* #endregion */

/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {
  .games .introSection, .web .introSection{
    min-height:100vh;
  }
  .introSection{
    margin-top:var(--spacer-thick);
  }
  .mainBody{
    flex-direction: column-reverse !important;
  }
  .mainBodyLeft{
    width:100%;
    margin-top:calc(var(--spacer) * -1);
    z-index:9;
  }
  .titlesWrapper .button{
    width:100% !important;
    margin-bottom:var(--spacer) !important
  }
  .wonminImage{
    padding-left:0;
    width:200px;
    min-height:232px;
    padding-bottom:var(--spacer-thick);
  }
  .name{
    text-align: center;
    font-size:1.9rem;
  }
  .nameSub{
    text-align: center;
    font-size:1.15rem;
  }
  .titleText{
    text-align: center;
    font-size:1.15rem;
  }
  .titlesWrapper{
    flex-direction: column;
  }
  .socialLinksWrapper{
    display:flex;
    justify-content: center;
    margin-top:var(--spacer-half);
    margin-bottom:var(--spacer-half);
  }
  .about__desc{
    text-align:justify;
  }
}

/* #endregion */
