.projectSection{
  padding-bottom: var(--spacer-thicc);
}

.projectSection .section__title{
  margin-bottom:var(--spacer-half);
}

.projects__grid {
  margin: 0 auto;
  overflow:visible;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 2em;
}

.projects__grid:not(:last-child) {
  margin-bottom: var(--spacer-thicc);
}

.project {
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--clr-bg-alt);
}

@media only screen and (max-width: 1250px) {
  .project {
    cursor:pointer;
  }
}

.worksSwiper .projectDetails{
  padding-bottom:0;
}

.worksSwiper .projectDetails h3 .link{
  white-space:nowrap;
}

.projectsSwiper .projectDetails{
  padding-bottom:0;
}

.projectDetails {
  padding:var(--spacer-thick);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.projectName{
  display:flex;
  width:100%;
  justify-content: center;
}

.projectName .link{
  display:flex;
  align-items: center;
}

.project__description {
  margin-top: 1em;
}

.project__subtitle {
  display:flex;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--shadow);
  margin-top:var(--spacer);
}

.inlineSpan{
  padding-top:var(--spacer-half);
}

.inlineFA {
  font-size:0.75rem;
  padding-left:0.35rem;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.project__links{
  display:flex;
  justify-content: center;
}

.project .link--icon {
  margin-left: var(--spacer-quarter);
  margin-right: var(--spacer-quarter);
  font-size:2rem;
}

.projectImage {
  width:100%;
  object-fit:cover;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.tooltip{
  font-size:.8em;
  line-height:125%;
}

.sideproject__title{
  padding-top:var(--spacer);
}


/* #region -----------------PAGINATION-----------------------------------*/
.customNavigationWrapper{
  display:flex;
  margin:auto;
  width:150px;
  justify-content: space-between;
}
.customNavigationButton{
  font-size:1em;
  cursor:pointer;
  color: var(--clr-primary);
}

.swiperSection {
  max-width:calc(1200px + var(--spacer-thick));
}
.swiper-wrapper {
  margin-top:var(--spacer-thick);
}
.swiper-button-prev, .swiper-button-next, .swiper-pagination {
  background:transparent;
}
.projectsSwiper .swiper-button-prev, .projectsSwiper .swiper-button-next{
  top:28% !important;
}
.swiper-button-prev, .swiper-button-next{
  color: var(--clr-primary);
  padding:var(--spacer);
  border-radius:100%;
  top:12%;
}
.swiper-button-disabled{
  opacity:0 !important;
}
.swiper-button-prev::after, .swiper-button-next::after{
  font-size:1.5em;
  font-weight:bolder;
}
.swiper-pagination-bullet{
  background:var(--clr-primary);
}
.swiper-pagination{
  top:calc(var(--spacer) * -1.25);
  bottom:revert !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 100px !important;
  margin: auto;
}
.swiperIcon {
  padding-right:.5em;
  display:flex;
  justify-content: space-between;
  align-items: flex-start
}

.swiperIconArrow{
  font-size:.5em;
}

/* #endregion */


/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {
  .project__description{
    text-align: justify;
  }
  .inlineSpan{
    text-align: center;
  }
}

/* #endregion */
