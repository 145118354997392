.checkboxWrapper{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.checkboxContainer{
  cursor:pointer;
  font-size:1rem;
  margin-left:var(--spacer-half);
  margin-right:var(--spacer-half);
}

.checkboxContainer span{
  margin-left:var(--spacer-quarter);
}

.skills__list {
  max-width: 500px;
  width: 95%;
  margin: 0 auto;
  margin-top:var(--spacer);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
}

/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {
  .skills.section, .section.education {
    margin-top:0;
    padding-top:var(--spacer-thick);
    padding-bottom:var(--spacer-thick);
  }
  .skills.section {
    margin-top:0;
    padding-top:var(--spacer-thick);
    padding-bottom:var(--spacer-thick);
  }
  .section.education {
    margin-top:0;
    padding-top:var(--spacer-thick);
    padding-bottom:0;
  }
}

/* #endregion */
