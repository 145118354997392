#root {
  padding-left: calc(100vw - 100%);
  padding-bottom:var(--spacer-thicc);
  margin-top: var(--spacer-thiccer);
}

@media only screen and (max-width: 900px) {
  #root {
    padding-left: 0;
    margin-top: var(--spacer) !important;
  }
}

:root{
  --spacer-thiccer: 8em;
  --spacer-thicc: 4em;
  --spacer-thick: 2em;
  --spacer: 1em;
  --spacer-half: 0.5em;
  --spacer-quarter: 0.25em;
  --border-radius:5px;
  --max-width-mobile: 900px;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --shadow-clr: rgba(100, 100, 111, 0.2);
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #323232;
}

/* NEITHER PROFESSION VARIABLES */
.wonmin {
  --clr-primary: #e86666;
}

/* GAMES PROFESSION VARIABLES */
.games {
  --clr-primary: #5f5aa2;
}

/* WEB PROFESSION VARIABLES */
.web {
  --clr-primary: #7daf9c;
}

body {
  margin: 0;
  font-family: "Poppins", "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:white;
  margin:0;
  border:0;
  padding:0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noselect {
  -webkit-user-drag: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.is-primary{color:#e86666;}
.is-secondary{color:#cc;}
.is-tertiary{color:#5f5aa2;}
.is-quaternary{color:#7daf9c;}

.is-bg-primary{background-color:#e86666 !important;color:var(--clr-bg-alt);}
.is-bg-secondary{background-color:#f1db4b !important;color:#323232;}
.is-bg-tertiary{background-color:#5f5aa2 !important;color:var(--clr-bg-alt);}
.is-bg-quaternary{background-color:#7daf9c !important;color:var(--clr-bg-alt);}

.is-inverted.is-bg-primary{background-color:var(--clr-bg-alt) !important;border-color:#e86666;color:#e86666;}
.is-inverted.is-bg-secondary{background-color:var(--clr-bg-alt) !important;border-color:#f1db4b;color:#f1db4b;}
.is-inverted.is-bg-tertiary{background-color:var(--clr-bg-alt) !important;border-color:#5f5aa2;color:#5f5aa2;}
.is-inverted.is-bg-quaternary{background-color:var(--clr-bg-alt) !important;border-color:#7daf9c;color:#7daf9c;}

.is-rounded-corner {border-radius: var(--border-radius);}

.is-hidden{
  display:none !important;
}

@media only screen and (min-width: 901px) {
  .is-hidden-desktop{
    display:none !important;
  }
}

@media only screen and (max-width: 900px) {
  .is-hidden-mobile{
    display:none !important;
  }
}

/* <editor-fold-------------------------------GLOBAL STYLES------------------------------- */

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: var(--clr-fg);
  background-color: var(--clr-bg);
}

::-moz-selection {
  background: var(--clr-primary);
  color: var(--clr-bg);
}

::-webkit-selection,
::selection {
  background: var(--clr-primary);
  color: var(--clr-bg);
}

h1,
h2,
h3,
h4 {
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.15rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

@media (max-width: 900px) {
  h1 {
    font-size: 2.6rem;
  }
}

/* #endregion------------------------------------------------------------------------------------- */

/* <editor-fold-------------------------------BUTTONS + LINKS------------------------------- */

.button {
  box-shadow:0 0.25em 0 var(--shadow-clr);
  border:.1em solid var(--shadow-clr);
  border-radius:var(--border-radius);
  text-align:center;
  padding:var(--spacer-half);
  transition: transform 0.05s ease-in-out;
}
.button svg{
  padding-right:.5em;
}
.button:active {
  transform:translateY(5px);
  box-shadow:none;
}
.button.is-bg-primary:hover{border:.1em solid #323232}
.button.is-bg-secondary:hover{border:.1em solid #323232}
.button.is-bg-tertiary:hover{border:.1em solid #323232}
.button.is-bg-quaternary:hover{border:.1em solid #323232}

.link {
  -webkit-user-drag: none;
  color: var(--clr-primary);
  position: relative;
}

.link.textDecoration {
  text-decoration: underline;
}

.link:not(.noline){
  padding: 0 0 0.3em 0;
}

.link:hover {
  color: var(--clr-primary);
}

.link:not(.noline)::before {
  content: "";
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--clr-primary);
  transition: width 0.2s ease-in;
}

.link--icon:not(.noline)::before{
  bottom:.2em !important;
}

.link:not(.noline):hover::before,
.link:not(.noline):focus::before {
  width: 100%;
}

.link--nav {
  color: var(--clr-fg);
  text-transform: lowercase;
  font-weight: 500;
}

.link--icon {
  color: var(--clr-fg);
  font-size: 1.2rem;
  cursor:pointer;
}

.btn {
  display: block;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: lowercase;
  transition: transform 0.2s ease-in-out;
}

.btn--outline {
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn--outline:focus,
.btn--outline:hover {
  color: var(--clr-bg);
}

.btn--outline:before {
  content: "";
  position: absolute;
  background-color: var(--clr-primary);
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.btn--outline:hover::before,
.btn--outline:focus::before {
  right: 0;
}

.btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
}

.btn--plain:hover {
  transform: translateY(-4px);
}

/* #endregion------------------------------------------------------------------------------------- */

/* #region ---------------------------------------LAYOUT---------------------------------------------- */

.center {
  display: flex;
  align-items: center;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
}

/* #endregion------------------------------------------------------------------------------------- */

/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {

}

/* #endregion */
