#navbar.is-fixed{
  padding-left: calc(100vw - 100%);
  position:fixed;
  top:0;
  width:100%;
  z-index:999;
  left:50%;
  transform:translateX(-50%);
}

#navbar.is-fixed .header{
  height: 3.5em;
}

#navbar.border-bottom{
  border-bottom:1px solid var(--shadow-clr);
}

.header {
  max-width:calc(1200px + var(--spacer-thick));
  padding-left:var(--spacer-thick);
  padding-right:var(--spacer-thick);
  margin: auto;
  justify-content: space-between;
}

.nav.left {
  display:flex;
  justify-content: flex-start;
  align-items: center;
}

.btn--icon.color-always{
  margin-left:var(--spacer-quarter);
}

.nav__list {
  display: flex;
}

.nav__list-item {
  margin-left: 1.5em;
  cursor:pointer;
}

.nav__hamburger {
  display: none;
  width: 1em;
}

.btn--icon {
  padding: 0;
  font-size: 1.2rem;
  color: var(--clr-primary);
}

.logoNav:active .btn--icon {
  transform: translate(2px) rotate(15deg);
}

/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {
  #navbar.is-fixed{
    padding-left: 0;
  }
}


/* #endregion */
