.skillsAndEducation{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  max-width:calc(1200px + var(--spacer-thick));
  padding:var(--spacer-thick);
  margin:auto;
}

.skillsAndEducation .mainBody{
  width:45%;
  align-items: flex-start;
  min-height:300px;
  margin:0;
  padding:0;
}

/* #region -----------------MOBILE-----------------------------------*/

@media only screen and (max-width: 900px) {
  .skillsAndEducation{
    flex-direction: column;
    max-width:100%;
  }
  .skillsAndEducation .mainBody{
    width:100%;
    min-height:410px;
    justify-content: flex-end;
    align-items: center;
    padding:0;
  }
}

/* #endregion */
