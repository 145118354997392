
.interests{
  max-width:100%;
}

.interests .skills__list{
  max-width:100%;
}

.interests .skills__list-item.is-inverted{
  border-style:solid !important;
  border-width:1px !important;
}

.interests .skills__list-item {
  font-size:.8rem;
  padding:var(--spacer-half);
}
