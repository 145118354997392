.pressRows{
  max-width:900px;
  margin:auto;
}

.press__date{
  font-weight:normal;
  font-size:.75em;
}

.pressWrapper:hover .timelineDot{
  transform:translateX(5px);
}

.pressWrapper .timelineDot{
  font-size:1.5em;
  margin-top:.35em;
  transition: transform 0.2s ease-in-out;
}

.pressWrapper .articleDetailWrapper{
  width:calc(100% - 75px);
}

@media only screen and (max-width: 900px) {
  .pressRows{
    max-width:100%;
  }
}
