.experiences__grid{
  max-width: 750px;
  margin: 0 auto;
  display: grid;
  grid-gap: 2em;
}

.experiences__grid .project {
  width:100%;
}

.inlineFAtitle{
  font-size:.75em;
  padding-left:.25em;
  padding-bottom:.05em;
}

.experience .projectTitle {
  margin-bottom:var(--spacer-quarter);
}

.experience .project__subtitle{
  margin-top:0;
}
