.footer {
  position:fixed;
  bottom:var(--spacer);
  right:var(--spacer);
  font-size:2rem;
  transition: transform 0.2s ease-in-out;
  color:#FFF;
  cursor:pointer;
  height:46px;
  background-color:transparent;
}

.footerIcon{
  box-shadow:var(--shadow);
  width:30px;
  height:30px;
  border-radius:100%;
  background-color:var(--clr-primary);
  padding:var(--spacer-quarter);
  border:.05em solid transparent;
}

.footer:hover .footerIcon{
  border:.05em solid #323232
}

.footer:active {
  transform:translateY(-5px);
}
